export class EmployeeExcelResponseModel {
  guidEmployee: string;
  idEmployee: number;
  idPackage: number;
  externalIdEmployee: string;
  nameSurname: string;
  positionCoefficient: number;
  positionCoefficientName: string;
  budget: string;
  lastYearPerformance: string;
  thisYearPerformance: string;
  location: string;
  workUnit: string;
  department: string;
  title: string;
  position: string;
  firstManager: string;
  lastManager: string;
  normType: string;
  dateOfBirth: Date | null;
  startDateOfEmployment: Date;
  startDateOfPosition: Date | null;
  level: number;
  status: string;
  wage: number;
  systemRecommendationIncreaseRate: number | null;
  finalIncreaseRate: number | null;
  systemRecommendationWage: number | null;
  finalWage: number | null;
  positionCriticality: string | null;
  employeeCriticality: string | null;
  gender: string | null;
  individualPolicy: number;
  individualPolicyPIR: number;
  email: string;
  managerRecommendationIncreaseRate: number | null;
  managerRecommendationWage: number | null;
  advancedPolicy: number | null;
  crAfterFirstIncrement: number | null;
  systemRecommendationPIR: number | null;
  finalPIR: number | null;
  isVisible: boolean;
  employeeType: string;
  policyTypeName: string;
  retirementStatus: string;
  numberOfSalariesInYear: number;
  wageInFirstCompletedSenario: number | null;
  //finalWageInFirstCompletedSenario: number | null;
  beforeFAO: number | null;
  afterFAO: number | null;
  employeeCompanyName: string;
  grossWage: number | null; //Mevcut Aylık Brüt Ücret (Wage / Maaş Sayısı) 
  salesPremiumRate: number | null;
  baseWageRate: number | null;
  salesPremiumAmount: number | null;
  finalMonthlyWage: number | null; //Nihai Aylık Ücret
  finalMonthlyWageForFinalWage: number | null; //Final Aylık Ücret (FinalWage / Maaş Sayısı) 
  payrollWage: number | null; //Bordro Ücreti (Final Aylık Ücret - Prim Oranı)
  currentMedianPIR: number;
  employeeSubDepartment: string;
  currentMedian: number | null;
  policy: number | null;
  pastPolicy: number | null;
  employeeNote: string; //Final Notu
  managerRecommendationNote: string;
}
