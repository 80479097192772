import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import JwtService from "@/core/services/JwtService";
import { KeyValueResponseModel } from "@/models/global/KeyValueResponseModel";
import ApiService from "@/core/services/ApiService";
import { FactorOrPolicyDataResponseModel } from "./_models/ResponseModels/FactorOrPolicyDataResponseModel";
import { ExcelPackageResponseModel } from "./_models/ResponseModels/ExcelPackageResponseModel";
import { GetEmployeeExcelPackagesComboListRequestModel } from "../companyManagement/_models/RequestModels/GetEmployeeExcelPackagesComboListRequestModel";
import { IsThereAnyRecordedFactorOrPolicyDataRequestModel } from "../companyManagement/_models/RequestModels/IsThereAnyRecordedFactorOrPolicyDataRequestModel";
import { UpdateUserActivityRequestModel } from "../companyManagement/_models/RequestModels/UpdateUserActivityRequestModel";
import { UpdateUserActivityForChangeCompanyRequestModel } from "../companyManagement/_models/RequestModels/UpdateUserActivityForChangeCompanyRequestModel";

@Module
export default class TopbarManagementModule extends VuexModule {
  employeeExcelPackagesComboList: Array<ExcelPackageResponseModel>;
  idExcelPackage = JwtService.getExcelPackageId();
  companyComboList: Array<KeyValueResponseModel>;
  idCompany = JwtService.getCompanyId();
  isThereFactorOrPolicyData: FactorOrPolicyDataResponseModel;
  idPolicyManagementOption = JwtService.getPolicyManagementOptionId();
  guidPolicyManagementOption = JwtService.getPolicyManagementOptionGuid();
  idEmployeeManagementOption = JwtService.getEmployeeManagementOptionId();
  idWageManagementOption = JwtService.getWageManagementOptionId();

  get getExcelPackagesList() {
    return this.employeeExcelPackagesComboList;
  }
  get getIdExcelPackage() {
    return this.idExcelPackage;
  }
  get getCompanyListForUser() {
    return this.companyComboList;
  }
  get getIdCompany() {
    return this.idCompany;
  }
  get getIsThereFactorOrPolicyData() {
    return this.isThereFactorOrPolicyData;
  }
  get getIdPolicyManagementOption() {
    return this.idPolicyManagementOption;
  }
  get getGuidPolicyManagementOption() {
    return this.guidPolicyManagementOption;
  }
  get getIdEmployeeManagementOption() {
    return this.idEmployeeManagementOption;
  }
  get getIdWageManagementOption() {
    return this.idWageManagementOption;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_EXCEL_PACKAGES_COMBO_LIST](excelPackagesList) {
    this.employeeExcelPackagesComboList = excelPackagesList;
  }

  @Mutation
  [Mutations.SET_ID_EXCEL_PACKAGE](idPackage) {
    this.idExcelPackage = idPackage;
    JwtService.saveExcelPackageId(idPackage);
  }

  @Mutation
  [Mutations.SET_COMPANY_COMBO_LIST_BY_USER](companyList) {
    this.companyComboList = companyList;
  }

  @Mutation
  [Mutations.SET_ID_COMPANY](idCompany) {
    this.idCompany = idCompany;
    JwtService.saveCompanyId(idCompany);
  }

  @Mutation
  [Mutations.SET_ID_POLICY_MANAGEMENT_OPTION](idPolicyManagementOption) {
    this.idPolicyManagementOption = idPolicyManagementOption;
    JwtService.savePolicyManagementOptionId(idPolicyManagementOption);
  }

  @Mutation
  [Mutations.SET_GUID_POLICY_MANAGEMENT_OPTION](guidPolicyManagementOption) {
    this.guidPolicyManagementOption = guidPolicyManagementOption;
    JwtService.savePolicyManagementOptionGuid(guidPolicyManagementOption);
  }

  @Mutation
  [Mutations.SET_ID_WAGE_MANAGEMENT_OPTION](idWageManagementOption) {
    this.idWageManagementOption = idWageManagementOption;
    JwtService.saveWageManagementOptionId(idWageManagementOption);
  }

  @Mutation
  [Mutations.SET_ID_EMPLOYEE_MANAGEMENT_OPTION](idEmployeeManagementOption) {
    this.idEmployeeManagementOption = idEmployeeManagementOption;
    JwtService.saveEmployeeManagementOptionId(idEmployeeManagementOption);
  }

  @Mutation
  [Mutations.SET_FACTOR_OR_POLICY_DATA](model) {
    this.isThereFactorOrPolicyData = model;
  }

  @Action
  [Actions.GET_EMPLOYEE_EXCEL_PACKAGES_COMBO_LIST](getEmployeeExcelPackagesComboListRequestModel: GetEmployeeExcelPackagesComboListRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetEmployeeExcelPackagesComboList", getEmployeeExcelPackagesComboListRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_EXCEL_PACKAGES_COMBO_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.SAVE_ID_EXCEL_PACKAGE](idPackage) {
    this.context.commit(Mutations.SET_ID_EXCEL_PACKAGE, idPackage);
  }

  @Action
  [Actions.DESTROY_ID_EXCEL_PACKAGE]() {
    JwtService.destroyExcelPackageId();
  }

  @Action
  [Actions.GET_COMPANY_COMBO_LIST_BY_USER]() {
    return new Promise((resolve, reject) => {
      ApiService.get("Company/GetCompanyComboListByUser")
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_COMPANY_COMBO_LIST_BY_USER,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.SAVE_ID_COMPANY](idCompany) {
    this.context.commit(Mutations.SET_ID_COMPANY, idCompany);
  }

  @Action
  [Actions.SAVE_ID_POLICY_MANAGEMENT_OPTION](idPolicyManagementOption) {
    this.context.commit(Mutations.SET_ID_POLICY_MANAGEMENT_OPTION, idPolicyManagementOption);
  }

  @Action
  [Actions.SAVE_GUID_POLICY_MANAGEMENT_OPTION](guidPolicyManagementOption) {
    this.context.commit(Mutations.SET_GUID_POLICY_MANAGEMENT_OPTION, guidPolicyManagementOption);
  }

  @Action
  [Actions.DESTROY_ID_POLICY_MANAGEMENT_OPTION]() {
    JwtService.destroyPolicyManagementOptionId();
  }

  @Action
  [Actions.SAVE_ID_WAGE_MANAGEMENT_OPTION](idWageManagementOption) {
    this.context.commit(Mutations.SET_ID_WAGE_MANAGEMENT_OPTION, idWageManagementOption);
  }

  @Action
  [Actions.DESTROY_ID_WAGE_MANAGEMENT_OPTION]() {
    JwtService.destroyWageManagementOptionId();
  }

  @Action
  [Actions.SAVE_ID_EMPLOYEE_MANAGEMENT_OPTION](idEmployeeManagementOption) {
    this.context.commit(Mutations.SET_ID_EMPLOYEE_MANAGEMENT_OPTION, idEmployeeManagementOption);
  }

  @Action
  [Actions.DESTROY_ID_EMPLOYEE_MANAGEMENT_OPTION]() {
    JwtService.destroyEmployeeManagementOptionId();
  }

  @Action
  [Actions.ISTHERE_ANY_RECORDED_FACTOR_OR_POLICY_DATA](isThereAnyRecordedFactorOrPolicyDataRequestModel: IsThereAnyRecordedFactorOrPolicyDataRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/IsThereAnyRecordedFactorOrPolicyData", isThereAnyRecordedFactorOrPolicyDataRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_FACTOR_OR_POLICY_DATA,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_USER_ACTIVITY](updateUserActivityRequestModel: UpdateUserActivityRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/UpdateUserActivity", updateUserActivityRequestModel
      )
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_USER_ACTIVITY_FOR_COMPANY](updateUserActivityForChangeCompanyRequestModel: UpdateUserActivityForChangeCompanyRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/UpdateUserActivityForChangeCompany", updateUserActivityForChangeCompanyRequestModel
      )
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}
