import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { UserViewModel } from "./_models/LoginResponseModel";
import {
  RoleMenuActionModel,
  RoleMenuModel,
  RoleModulesModel,
} from "./_models/UserRoleResponseModel";
import store, { resetState } from "@/store";
import { VerifyUserPasswordResponseModel } from "../userManagement/_models/ResponseModels/VerifyUserPasswordResponseModel";
import { GetRoleModulesListRequestModel } from "../userManagement/_models/RequestModels/GetRoleModulesListRequestModel";
import { GetRoleMenuListRequestModel } from "../userManagement/_models/RequestModels/GetRoleMenuListRequestModel";
import { GetRoleMenuActionListRequestModel } from "../userManagement/_models/RequestModels/GetRoleMenuActionListRequestModel";
import { RefreshTokenLoginRequestModel } from "../companyManagement/_models/RequestModels/RefreshTokenLoginRequestModel";
import { RefreshTokenLoginResponseModel } from "./_models/RefreshTokenLoginResponseModel";
import ApiService from "@/core/services/ApiService";

export interface UserAuthInfo {
  user: UserViewModel;
  isAuthenticated: boolean;
  moduleList: Array<RoleModulesModel> | undefined;
  menuList: Array<RoleMenuModel> | undefined;
  roleMenuActionList: Array<RoleMenuActionModel> | undefined;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  user = JwtService.getUserView();
  accessToken = JwtService.getAccessToken();
  refreshToken = JwtService.getRefreshToken();
  isAuthenticated = !!JwtService.getUserView().accessToken;
  // moduleList = JwtService.getUserView().userRole?.roleModulesList;
  // menuList = JwtService.getUserView().userRole?.roleMenusList;
  // roleMenuActionList = JwtService.getUserView().userRole?.roleMenusActionList;

  moduleList: Array<RoleModulesModel> | undefined;
  menuList: Array<RoleMenuModel> | undefined;
  roleMenuActionList: Array<RoleMenuActionModel> | undefined;
  verifyUserPasswordResponseModel: VerifyUserPasswordResponseModel;
  refreshTokenLoginResponseModel: RefreshTokenLoginResponseModel;

  get currentUser(): UserViewModel {
    return this.user;
  }
  get getAccessToken() {
    return this.accessToken;
  }
  get getRefreshToken() {
    return this.refreshToken;
  }
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }
  get getModuleList() {
    return this.moduleList;
  }
  get getMenuList() {
    return this.menuList;
  }
  get getRoleMenuActionAuthList() {
    return this.roleMenuActionList;
  }
  get getVerifyUserPasswordResponseModel() {
    return this.verifyUserPasswordResponseModel;
  }
  get getRefreshTokenLoginResponseModel() {
    return this.refreshTokenLoginResponseModel;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    //her ihtimale karşı local storage'da veya sessions storage'da veri kaldıysa ilk olarak onları temizliyoruz.
    window.localStorage.clear();
    window.sessionStorage.clear();

    this.isAuthenticated = true;
    this.user = user;
    JwtService.saveUserView(user);
    JwtService.saveUserId(user.idUser)
    JwtService.saveAccessToken(user.accessToken);
    JwtService.saveRefreshToken(user.refreshToken);
    //JwtService.saveUserRole(user.userRole);
  }

  @Mutation
  [Mutations.SET_REFRESH_TOKEN_LOGIN](refreshTokenLoginResponse) {
    this.refreshTokenLoginResponseModel = refreshTokenLoginResponse;
    JwtService.saveAccessToken(refreshTokenLoginResponse.accessToken);
    JwtService.saveRefreshToken(refreshTokenLoginResponse.refreshToken);

    const user = JwtService.getUserView();
    user.accessToken = refreshTokenLoginResponse.accessToken;
    user.refreshToken = refreshTokenLoginResponse.refreshToken;
    window.localStorage.setItem("user_view", JSON.stringify(user));
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    resetState();
    this.isAuthenticated = false;
    this.user = {} as UserViewModel;
    store.dispatch(Actions.SAVE_ID_POLICY_MANAGEMENT_OPTION, null);
    store.dispatch(Actions.SAVE_GUID_POLICY_MANAGEMENT_OPTION, null);
    store.dispatch(Actions.SAVE_ID_WAGE_MANAGEMENT_OPTION, null);
    store.dispatch(Actions.SAVE_ID_EXCEL_PACKAGE, null);
    store.dispatch(Actions.SAVE_ID_COMPANY, null);
    JwtService.destroyAccessToken();
    JwtService.destroyRefreshToken();
    JwtService.destroyUserView();
    //JwtService.destroyUserRole();
    JwtService.destroyPolicyManagementOptionId();
    JwtService.destroyWageManagementOptionId();
    JwtService.destroyExcelPackageId();
    JwtService.destroyCompanyId();
    JwtService.destroyEmployeeManagementOptionId();
  }

  @Mutation
  [Mutations.SET_ROLE_MODULE_LIST](moduleList) {
    this.moduleList = moduleList;
  }

  @Mutation
  [Mutations.SET_ROLE_MENU_LIST](menuList) {
    this.menuList = menuList;
  }

  @Mutation
  [Mutations.SET_ROLE_MENU_ACTION_LIST](roleMenuActionList) {
    this.roleMenuActionList = roleMenuActionList;
  }

  @Mutation
  [Mutations.SET_VERIFY_USER_PASSWORD](verifyUserPassword) {
    this.verifyUserPasswordResponseModel = verifyUserPassword;
  }

  //Action çalıştırarak (dispatch) Backend'deki bir web servisle iletişim kurarız ve bir veri elde ederiz.
  //Elimizdeki veriyi Mutation'a commit ederiz.
  //Mutation'a gelen ifadeye göre State'i güncelleriz.
  //State üzerindeki veriye Getters ile ulaşarak proje üzerindeki tüm componentlerden erişim sağlarız.
  @Action
  [Actions.LOGIN](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("User/LogIn", credentials)
        .then((response: any) => {
          // request succeeded
          if (response.result) {
            this.context.commit(Mutations.SET_AUTH, response.data);
          }
          resolve(response); // return response data to calling function
        })
        .catch((error) => {
          // request failed
          reject(error); // return error to calling function
        });
    });
  }

  @Action
  [Actions.REFRESH_TOKEN_LOGIN](refreshTokenLoginRequestModel: RefreshTokenLoginRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("User/RefreshTokenLogin", refreshTokenLoginRequestModel)
        .then((response: any) => {
          // request succeeded
          if (response.result) {
            this.context.commit(Mutations.SET_REFRESH_TOKEN_LOGIN, response.data);
          }
          resolve(response); // return response data to calling function
        })
        .catch((error) => {
          // request failed
          reject(error); // return error to calling function
        });
    });
  }


  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("User/ForgotPassword", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    return new Promise((resolve, reject) => {
      ApiService.get("User/LogOut")
        .then((response: any) => {
          // request succeeded
          if (response != undefined && response.result) {
            this.context.commit(Mutations.PURGE_AUTH);
          }
          resolve(response); // return response data to calling function
        })
        .catch((error) => {
          // request failed
          reject(error); // return error to calling function
        });
    });
  }

  @Action
  [Actions.GET_ROLE_MODULE_LIST](getRoleModulesListRequestModel: GetRoleModulesListRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("User/GetRoleModulesList", getRoleModulesListRequestModel)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_ROLE_MODULE_LIST, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_ROLE_MENU_LIST](getRoleMenuListRequestModel: GetRoleMenuListRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "User/GetRoleMenuList", getRoleMenuListRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_ROLE_MENU_LIST, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_ROLE_MENU_ACTION_LIST](getRoleMenuActionListRequestModel: GetRoleMenuActionListRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "User/GetRoleMenuActionList", getRoleMenuActionListRequestModel)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_ROLE_MENU_ACTION_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }



  // @Action
  // [Actions.REGISTER](credentials) {
  //   return ApiService.post("register", credentials)
  //     .then((response) => {
  //       this.context.commit(Mutations.SET_AUTH, response);
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //     });
  // }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (this.user.accessToken) {
      // ApiService.setHeader();
      // ApiService.post("verify_token", payload)
      //   .then((response: any) => {
      //     this.context.commit(Mutations.SET_AUTH, response.data);
      //   })
      //   .catch(({ response }) => {
      //     this.context.commit(Mutations.SET_ERROR, response.message);
      //     this.context.commit(Mutations.PURGE_AUTH);
      //   });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.VERIFY_USER_PASSWORD](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("User/VerifyUserPassword", credentials)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_VERIFY_USER_PASSWORD,
              response.data
            );
          }
          resolve(response); // return response data to calling function
        })
        .catch((error) => {
          // request failed
          reject(error); // return error to calling function
        });
    });
  }
}
