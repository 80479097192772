import ApiService from "@/core/services/ApiService";
import { KeyValueResponseModel } from "@/models/global/KeyValueResponseModel";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AddEmployeeRequestModel } from "./_models/RequestModels/AddEmployeeRequestModel";
import { CompanyRequestModel } from "./_models/RequestModels/CompanyRequestModel";
import { DeleteExcelPackageRequestModel } from "./_models/RequestModels/DeleteExcelPackageRequestModel";
import { EmployeePositionRequestModel } from "./_models/RequestModels/EmployeePositionRequestModel";
import { ImportExcelRequestModel } from "./_models/RequestModels/ImportExcelRequestModel";
import { IncreaseRateRequestModel } from "./_models/RequestModels/IncreaseRateRequestModel";
import { LocationFactorRequestModel } from "./_models/RequestModels/LocationFactorRequestModel";
import { PositionFactorRequestModel } from "./_models/RequestModels/PositionFactorRequestModel";
import { RatingScenarioRequestModel } from "./_models/RequestModels/RatingScenarioRequestModel";
import { SeniorityFactorRequestModel } from "./_models/RequestModels/SeniorityFactorRequestModel";
import { WageFactorRequestModel } from "./_models/RequestModels/WageFactorRequestModel";
import { SalaryLetterMailRequestModel } from "./_models/RequestModels/SalaryLetterMailRequestModel";
import { CompanyResponseModel } from "./_models/ResponseModels/CompanyResponseModel";
import { EmployeeExcelResponseModel } from "./_models/ResponseModels/EmployeeExcelResponseModel";
import { EmployeePositionResponseModel } from "./_models/ResponseModels/EmployeePositionResponseModel";
import { LocationFactorResponseModel } from "./_models/ResponseModels/LocationFactorResponseModel";
import { MasterMQForExcelImportResponseModel } from "./_models/ResponseModels/MasterMQForExcelImportResponseModel";
import { MaxMinFilterResponseModel } from "./_models/ResponseModels/MaxMinFilterResponseModel";
import { PolicyGrandTotalInfoProcedureResponseModel } from "./_models/ResponseModels/PolicyGrandTotalInfoProcedureResponseModel";
import { PolicyProcedureResponseModel } from "./_models/ResponseModels/PolicyProcedureResponseModel";
import { PositionFactorResponseModel } from "./_models/ResponseModels/PositionFactorResponseModel";
import { RatingScenarioResponseModel } from "./_models/ResponseModels/RatingScenarioResponseModel";
import { SectorLevelResponseModel } from "./_models/ResponseModels/SectorLevelResponseModel";
import { SeniorityFactorResponseModel } from "./_models/ResponseModels/SeniorityFactorResponseModel";
import { WageFactorResponseModel } from "./_models/ResponseModels/WageFactorResponseModel";
import { UserAuthorityByExcelPackageResponseModel } from "./_models/ResponseModels/UserAuthorityByExcelPackageResponseModel";
import { MaxMinFilterRequestModel } from "./_models/RequestModels/MaxMinFilterRequestModel";
import { CollectiveEmployeeHidingRequestModel } from "./_models/RequestModels/CollectiveEmployeeHidingRequestModel";
import { EmployeeHidingRequestModel } from "./_models/RequestModels/EmployeeHidingRequestModel";
import { WagePolicyRequestModel } from "./_models/RequestModels/WagePolicyRequestModel";
import { CompanyMarketDataRequestModel } from "./_models/RequestModels/CompanyMarketDataRequestModel";
import { PolicyFactorNoteRequestModel } from "./_models/RequestModels/PolicyFactorNoteRequestModel";
import { PolicyFactorNoteKeyValueResponseModel } from "./_models/ResponseModels/PolicyFactorNoteKeyValueResponseModel";
import { PolicyFactorNoteResponseModel } from "./_models/ResponseModels/PolicyFactorNoteResponseModel";
import { InsertAllRatingScenarioToValueRequestModel } from "./_models/RequestModels/InsertAllRatingScenarioToValueRequestModel";
import { UpdatePırCalculationParameterRequestModel } from "./_models/RequestModels/UpdatePırCalculationParameterRequestModel";
import { CopyPolicyFromReferenceExcelPackageRequestModel } from "./_models/RequestModels/CopyPolicyFromReferenceExcelPackageRequestModel";
import { GetPirCalculationParameterResponseModel } from "./_models/ResponseModels/GetPirCalculationParameterResponseModel";
import { InsertPolicyFromExcelRequestModel } from "./_models/RequestModels/InsertPolicyFromExcelRequestModel";
import { PositionEmployeeResponseModel } from "./_models/ResponseModels/PositionEmployeeResponseModel";
import { SalesPremiumRateRequestModel } from "./_models/RequestModels/SalesPremiumRateRequestModel";
import { FinalWageRequestModel } from "./_models/RequestModels/FinalWageRequestModel";
import { UpdateScenarioNameRequestModel } from "./_models/RequestModels/UpdateScenarioNameRequestModel";
import { ExcelPackageParameterRequestModel } from "@/models/global/ExcelPackageParameterRequestModel";
import { GetEmployeeByIdRequestModel } from "./_models/RequestModels/GetEmployeeByIdRequestModel";
import { DeleteEmployeeRequestModel } from "./_models/RequestModels/DeleteEmployeeRequestModel";
import { CalculateSeniorityInformationRequestModel } from "./_models/RequestModels/CalculateSeniorityInformationRequestModel";
import { GetCompanyRequestModel } from "./_models/RequestModels/GetCompanyRequestModel";
import { GetEmployeePositionByIdRequestModel } from "./_models/RequestModels/GetEmployeePositionByIdRequestModel";
import { GetPositionEmployeeListRequestModel } from "./_models/RequestModels/GetPositionEmployeeListRequestModel";
import { GetHistoryOfExcelImportListRequestModel } from "./_models/RequestModels/GetHistoryOfExcelImportListRequestModel";
import { RetryToCompleteExcelImportForEmployeeRequestModel } from "./_models/RequestModels/RetryToCompleteExcelImportForEmployeeRequestModel";
import { GetAllScenarioForCompanyRequestModel } from "./_models/RequestModels/GetAllScenarioForCompanyRequestModel";
import { GetPolicyFactorNoteRequestModel } from "./_models/RequestModels/GetPolicyFactorNoteRequestModel";
import { GetPolicyManagementOptionComboListRequestModel } from "./_models/RequestModels/GetPolicyManagementOptionComboListRequestModel";
import { GetAllScenarioForCompanyResponseModel } from "./_models/ResponseModels/GetAllScenarioForCompanyResponseModel";
import { GetAllNoteTitlesResponseModel } from "./_models/ResponseModels/GetAllNoteTitlesResponseModel";
import { ApplyMarketAgeingRequestModel } from "./_models/RequestModels/ApplyMarketAgeingRequestModel";
import { ApplyPolicyAgeingRequestModel } from "./_models/RequestModels/ApplyPolicyAgeingRequestModel";

@Module
export default class CompanyManagementModule extends VuexModule {
  employeeListResponseModel: Array<EmployeeExcelResponseModel>;
  policyInfoResponseModel: Array<PolicyProcedureResponseModel>;
  policyGrandTotalInfoResponseModel: Array<PolicyGrandTotalInfoProcedureResponseModel>;
  wageFactorsResponseModel: WageFactorResponseModel;
  seniorityFactorsResponseModel: SeniorityFactorResponseModel;
  positionFactorsResponseModel: Array<PositionFactorResponseModel>;
  companyListResponseModel: Array<CompanyResponseModel>;
  companyRequestModel: CompanyRequestModel;
  sectorResponseModel: Array<KeyValueResponseModel>;
  employeeLevelListResponseModel: Array<KeyValueResponseModel>;
  employeeStatusListResponseModel: Array<KeyValueResponseModel>;
  employeeWorkUnitListResponseModel: Array<KeyValueResponseModel>;
  employeeDepartmentListResponseModel: Array<KeyValueResponseModel>;
  employeePositionListResponseModel: Array<KeyValueResponseModel>;
  employeeNormTypeListResponseModel: Array<KeyValueResponseModel>;
  masterMQForExcelImportResponseModel: Array<MasterMQForExcelImportResponseModel>;
  ratingScenarioResponseModel: RatingScenarioResponseModel;
  maxMinFilterResponseModel: Array<MaxMinFilterResponseModel>;
  employeePositionCriticalityLevelsListResponseModel: Array<KeyValueResponseModel>;
  levelListBySector: Array<SectorLevelResponseModel>;
  addEmployeeRequestModel: AddEmployeeRequestModel;
  employeePositionResponseModel: Array<EmployeePositionResponseModel>;
  employeePositionRequestModel: EmployeePositionRequestModel;
  allScenarioForCompany: Array<GetAllScenarioForCompanyResponseModel>;
  employeePerformanceListResponseModel: Array<KeyValueResponseModel>;
  employeeTitleListResponseModel: Array<KeyValueResponseModel>;
  employeeLocationListResponseModel: Array<KeyValueResponseModel>;
  locationFactorsResponseModel: Array<LocationFactorResponseModel>;
  scenarioStatus: boolean;
  termYearComboList: Array<KeyValueResponseModel>;
  userAuthorityByExcelPackageResponseModel: UserAuthorityByExcelPackageResponseModel;
  policyFactorNoteRequestModel: PolicyFactorNoteRequestModel;
  policyFactorNoteKeyValueResponseModel: Array<PolicyFactorNoteKeyValueResponseModel>;
  policyFactorNoteResponseModel: Array<PolicyFactorNoteResponseModel>;
  noteTitlesResponseModel: Array<GetAllNoteTitlesResponseModel>;
  policyManagementOptionComboListResponseModel: Array<KeyValueResponseModel>;
  employeePolicyTypeListResponseModel: Array<KeyValueResponseModel>;
  getPirCalculationParameterResponseModel: GetPirCalculationParameterResponseModel;
  employeeCompanyListResponseModel: Array<KeyValueResponseModel>;
  positionEmployeeResponseModel: Array<PositionEmployeeResponseModel>;

  get getAllEmployees() {
    return this.employeeListResponseModel;
  }
  get getPolicyInfoForExcelPackageID() {
    return this.policyInfoResponseModel;
  }
  get getPolicyGrandTotalInfoForExcelPackageID() {
    return this.policyGrandTotalInfoResponseModel;
  }
  get getWageFactorList() {
    return this.wageFactorsResponseModel;
  }
  get getSeniorityFactorList() {
    return this.seniorityFactorsResponseModel;
  }
  get getPositionFactorList() {
    return this.positionFactorsResponseModel;
  }
  get getAllCompanies() {
    return this.companyListResponseModel;
  }
  get getCompanyInfoRequestModel() {
    return this.companyRequestModel;
  }
  get getAllSectors() {
    return this.sectorResponseModel;
  }
  get getEmployeeLevelList() {
    return this.employeeLevelListResponseModel;
  }
  get getEmployeeStatusList() {
    return this.employeeStatusListResponseModel;
  }
  get getEmployeeWorkUnitList() {
    return this.employeeWorkUnitListResponseModel;
  }
  get getEmployeeDepartmentList() {
    return this.employeeDepartmentListResponseModel;
  }
  get getEmployeePositionList() {
    return this.employeePositionListResponseModel;
  }
  get getEmployeeNormTypeList() {
    return this.employeeNormTypeListResponseModel;
  }
  get getHistoryOfExcelImportList() {
    return this.masterMQForExcelImportResponseModel;
  }
  get getRatingScenarioResponseModel() {
    return this.ratingScenarioResponseModel;
  }
  get getMaxMinFilterResponseModel() {
    return this.maxMinFilterResponseModel;
  }
  get getEmployeePositionCriticalityLevelsList() {
    return this.employeePositionCriticalityLevelsListResponseModel;
  }
  get getLevelsBySector() {
    return this.levelListBySector;
  }
  get getEmployee() {
    return this.addEmployeeRequestModel;
  }
  get getEmployeePositionListForPositionManagement() {
    return this.employeePositionResponseModel;
  }
  get getEmployeePosition() {
    return this.employeePositionRequestModel;
  }
  get getScenariosForCompany() {
    return this.allScenarioForCompany;
  }
  get getEmployeePerformanceList() {
    return this.employeePerformanceListResponseModel;
  }
  get getEmployeeTitleList() {
    return this.employeeTitleListResponseModel;
  }
  get getEmployeeLocationList() {
    return this.employeeLocationListResponseModel;
  }
  get getLocationFactorList() {
    return this.locationFactorsResponseModel;
  }
  get getScenarioStatus() {
    return this.scenarioStatus;
  }
  get getSenarioTermYearComboList() {
    return this.termYearComboList;
  }
  get getUserAuthorityInfoByExcelPackage() {
    return this.userAuthorityByExcelPackageResponseModel;
  }
  get getPolicyFactorNoteByExcelPackage() {
    return this.policyFactorNoteRequestModel;
  }
  get getPolicyFactorNoteKeyValueResponseModel() {
    return this.policyFactorNoteKeyValueResponseModel;
  }
  get getAllPolicyAndFactorNotes() {
    return this.policyFactorNoteResponseModel;
  }
  get getNoteTitles() {
    return this.noteTitlesResponseModel;
  }
  get getPolicyManagementOptionComboListResponseModel() {
    return this.policyManagementOptionComboListResponseModel;
  }
  get getEmployeePolicyTypeList() {
    return this.employeePolicyTypeListResponseModel;
  }
  get getPirCalculationParameter() {
    return this.getPirCalculationParameterResponseModel;
  }
  get getEmployeeCompanyList() {
    return this.employeeCompanyListResponseModel;
  }

  get getPositionEmployeeList() {
    return this.positionEmployeeResponseModel;
  }

  @Mutation
  [Mutations.SET_EXCEL_EMPLOYEE_DATA](employeeList) {
    this.employeeListResponseModel = employeeList;
  }

  @Mutation
  [Mutations.SET_POLICY_INFO_FOR_EXCEL_PACKAGE_ID](policyInfo) {
    this.policyInfoResponseModel = policyInfo;
  }

  @Mutation
  [Mutations.SET_POLICY_GRAND_TOTAL_INFO_FOR_EXCEL_PACKAGE_ID](
    policyGrandTotalInfo
  ) {
    this.policyGrandTotalInfoResponseModel = policyGrandTotalInfo;
  }

  @Mutation
  [Mutations.SET_WORK_UNIT_WAGE_FACTOR_LIST](wageFactorList) {
    this.wageFactorsResponseModel = wageFactorList;
  }

  @Mutation
  [Mutations.SET_LEVEL_SENIORITY_FACTOR_LIST](seniorityFactorList) {
    this.seniorityFactorsResponseModel = seniorityFactorList;
  }

  @Mutation
  [Mutations.SET_POSITION_CRITICALITY_FACTOR_LIST](positionFactorList) {
    this.positionFactorsResponseModel = positionFactorList;
  }

  @Mutation
  [Mutations.SET_COMPANY_LIST](companyList) {
    this.companyListResponseModel = companyList;
  }

  @Mutation
  [Mutations.SET_COMPANY](company) {
    this.companyRequestModel = company;
  }

  @Mutation
  [Mutations.SET_SECTOR_LIST](sectorList) {
    this.sectorResponseModel = sectorList;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_LEVELS_LIST](employeeLevelList) {
    this.employeeLevelListResponseModel = employeeLevelList;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_STATUS_LIST](employeeStatusList) {
    this.employeeStatusListResponseModel = employeeStatusList;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_WORK_UNITS_LIST](employeeWorkUnitList) {
    this.employeeWorkUnitListResponseModel = employeeWorkUnitList;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_DEPARTMENTS_LIST](employeeDepartmentList) {
    this.employeeDepartmentListResponseModel = employeeDepartmentList;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_POSITIONS_LIST](employeePositionList) {
    this.employeePositionListResponseModel = employeePositionList;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_NORM_TYPES_LIST](employeeNormTypeList) {
    this.employeeNormTypeListResponseModel = employeeNormTypeList;
  }

  @Mutation
  [Mutations.SET_HISTORY_OF_EXCEL_IMPORT_LIST](
    masterMQForExcelImportResponseModel
  ) {
    this.masterMQForExcelImportResponseModel =
      masterMQForExcelImportResponseModel;
  }

  @Mutation
  [Mutations.SET_RATING_SCENARIO_LIST](model) {
    this.ratingScenarioResponseModel = model;
  }

  @Mutation
  [Mutations.SET_MAXMIN_FILTER](model) {
    this.maxMinFilterResponseModel = model;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_POSITION_CRITICALITY_LEVELS](
    employeePositionCriticalityLevelsList
  ) {
    this.employeePositionCriticalityLevelsListResponseModel =
      employeePositionCriticalityLevelsList;
  }

  @Mutation
  [Mutations.SET_LEVEL_LIST_BY_SECTOR](model) {
    this.levelListBySector = model;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE](model) {
    this.addEmployeeRequestModel = model;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_POSITION_LIST](model) {
    this.employeePositionResponseModel = model;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_POSITION](model) {
    this.employeePositionRequestModel = model;
  }

  @Mutation
  [Mutations.SET_ALL_SCENARIO_FOR_COMPANY](model) {
    this.allScenarioForCompany = model;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_PERFORMANCES](employeePerformanceList) {
    this.employeePerformanceListResponseModel = employeePerformanceList;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_TITLES](employeeTitleList) {
    this.employeeTitleListResponseModel = employeeTitleList;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_LOCATIONS](employeeLocationList) {
    this.employeeLocationListResponseModel = employeeLocationList;
  }

  @Mutation
  [Mutations.SET_LOCATION_FACTORS_LIST](locationFactorList) {
    this.locationFactorsResponseModel = locationFactorList;
  }

  @Mutation
  [Mutations.SET_SCENARIO_STATUS](scenarioStatus) {
    this.scenarioStatus = scenarioStatus;
  }

  @Mutation
  [Mutations.SET_TERM_YEAR_COMBO_LIST](model) {
    this.termYearComboList = model;
  }

  @Mutation
  [Mutations.SET_USER_AUTHORITY_BY_EXCEL_PACKAGE](model) {
    this.userAuthorityByExcelPackageResponseModel = model;
  }

  @Mutation
  [Mutations.SET_POLICY_FACTOR_NOTE](model) {
    this.policyFactorNoteRequestModel = model;
  }

  @Mutation
  [Mutations.SET_POLICY_FACTOR_NOTE_KEY_VALUE_MODEL](model) {
    this.policyFactorNoteKeyValueResponseModel = model;
  }

  @Mutation
  [Mutations.SET_ALL_POLICY_FACTOR_NOTES](model) {
    this.policyFactorNoteResponseModel = model;
  }

  @Mutation
  [Mutations.SET_ALL_NOTE_TITLES](model) {
    this.noteTitlesResponseModel = model;
  }

  @Mutation
  [Mutations.SET_POLICY_MANAGEMENT_OPTION_COMBO_LIST](model) {
    this.policyManagementOptionComboListResponseModel = model;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_POLICY_TYPES_LIST](model) {
    this.employeePolicyTypeListResponseModel = model;
  }

  @Mutation
  [Mutations.SET_GET_PIR_CALCULATION_PARAMETER](model) {
    this.getPirCalculationParameterResponseModel = model;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_COMPANY_FOR_NEW_EMPLOYEE](employeeCompanyList) {
    this.employeeCompanyListResponseModel = employeeCompanyList;
  }

  @Mutation
  [Mutations.SET_POSITION_EMPLOYEE_LIST](model) {
    this.positionEmployeeResponseModel = model;
  }

  @Action
  [Actions.INSERT_EXCEL_EMPLOYEE_DATA](
    importExcelRequestModel: ImportExcelRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/InsertExcelEmployeeData",
        importExcelRequestModel
      )
        .then((response) => {
          // request succeeded
          resolve(response); // return response data to calling function
        })
        .catch((error) => {
          // request failed
          reject(error); // return error to calling function
        });
    });
  }

  @Action
  [Actions.GET_EXCEL_EMPLOYEE_DATA](excelPackageParameterRequestModel: EmployeeExcelResponseModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetExcelEmployeeData", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EXCEL_EMPLOYEE_DATA,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_POLICY_INFO_FOR_EXCEL_PACKAGE_ID](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetPolicyInfoForExcelPackageId", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_POLICY_INFO_FOR_EXCEL_PACKAGE_ID,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_POLICY_GRAND_TOTAL_INFO_FOR_EXCEL_PACKAGE_ID](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetPolicyGrandTotalInfoForExcelPackageId", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_POLICY_GRAND_TOTAL_INFO_FOR_EXCEL_PACKAGE_ID,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.INSERT_WAGE_POLICY](wagePolicyRequestModel: WagePolicyRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/InsertWagePolicy", wagePolicyRequestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.INSERT_COMPANY_MARKET_DATA](
    companyMarketDataRequestModel: CompanyMarketDataRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/InsertCompanyMarketData",
        companyMarketDataRequestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_WORK_UNIT_WAGE_FACTOR_LIST](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetWageFactorList", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_WORK_UNIT_WAGE_FACTOR_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.INSERT_WAGE_FACTOR_FOR_WORK_UNIT](
    wageFactorRequestModel: WageFactorRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/InsertWageFactorForWorkUnit",
        wageFactorRequestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_LEVEL_SENIORITY_FACTOR_LIST](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetSeniorityFactorList", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_LEVEL_SENIORITY_FACTOR_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.INSERT_SENIORITY_FACTOR_FOR_LEVEL](
    seniorityFactorRequestModel: SeniorityFactorRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/InsertSeniorityFactorForLevel",
        seniorityFactorRequestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_POSITION_CRITICALITY_FACTOR_LIST](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetPositionFactorList", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_POSITION_CRITICALITY_FACTOR_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.INSERT_POSITION_CRITICALITY_FACTOR](
    positionFactorRequestModel: PositionFactorRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/InsertPositionFactor",
        positionFactorRequestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_COMPANY_LIST]() {
    return new Promise((resolve, reject) => {
      ApiService.get("Company/GetCompanyList")
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_COMPANY_LIST, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_COMPANY](getCompanyRequestModel: GetCompanyRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/GetCompany", getCompanyRequestModel)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_COMPANY, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_SECTOR_LIST]() {
    return new Promise((resolve, reject) => {
      ApiService.get("Company/GetSectorComboList")
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_SECTOR_LIST, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_COMPANY](companyRequestModel: CompanyRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/UpdateCompany", companyRequestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_INCREASE_RATE_BY_EMPLOYEE](
    requestModel: IncreaseRateRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/UpdateIncreaseRateByEmployee", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.DELETE_EXCEL_PACKAGE](
    deleteExcelPackageRequestModel: DeleteExcelPackageRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/DeleteExcelPackage",
        deleteExcelPackageRequestModel
      )
        .then((response) => {
          // request succeeded
          resolve(response); // return response data to calling function
        })
        .catch((error) => {
          // request failed
          reject(error); // return error to calling function
        });
    });
  }

  @Action
  [Actions.ADD_OR_UPDATE_EMPLOYEE_TO_EXCEL_PACKAGE](
    addEmployeeRequestModel: AddEmployeeRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/AddOrUpdateEmployeeToExcelPackage",
        addEmployeeRequestModel
      )
        .then((response) => {
          // request succeeded
          resolve(response); // return response data to calling function
        })
        .catch((error) => {
          // request failed
          reject(error); // return error to calling function
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_LEVELS](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetEmployeeLevelsForNewEmployee",
        excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_LEVELS_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_STATUS](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetEmployeeStatusForNewEmployee",
        excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_STATUS_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_WORK_UNITS](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetEmployeeWorkUnitsForNewEmployee",
        excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_WORK_UNITS_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_DEPARTMENTS](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetEmployeeDepartmentsForNewEmployee", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_DEPARTMENTS_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_POSITIONS](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetEmployeePositionsForNewEmployee", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_POSITIONS_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_NORM_TYPES]() {
    return new Promise((resolve, reject) => {
      ApiService.get("Company/GetEmployeeNormTypes")
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_NORM_TYPES_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_HISTORY_OF_EXCEL_IMPORT](getHistoryOfExcelImportListRequestModel: GetHistoryOfExcelImportListRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetHistoryOfExcelImportList", getHistoryOfExcelImportListRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_HISTORY_OF_EXCEL_IMPORT_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.RETRY_TO_COMPLETE_EXCEL_IMPORT_FOR_EMPLOYEE](retryToCompleteExcelImportForEmployeeRequestModel: RetryToCompleteExcelImportForEmployeeRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/RetryToCompleteExcelImportForEmployee", retryToCompleteExcelImportForEmployeeRequestModel
      )
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_RATING_SCENARIO_LIST](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetRatingScenarioList", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_RATING_SCENARIO_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.INSERT_RATING_SCENARIO](
    ratingScenarioRequestModel: RatingScenarioRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/InsertRatingScenario",
        ratingScenarioRequestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_MAXMIN_FILTER](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/GetMaxMinFilter", excelPackageParameterRequestModel)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_MAXMIN_FILTER, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_MAXMIN_FILTER](
    maxMinFilterRequestModel: MaxMinFilterRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/UpdateMaxMinFilter", maxMinFilterRequestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_POSITION_CRITICALITY_LEVELS]() {
    return new Promise((resolve, reject) => {
      ApiService.get(
        "Company/GetEmployeePositionCriticalityLevelsForNewEmployee"
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_POSITION_CRITICALITY_LEVELS,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_LEVEL_LIST_BY_SECTOR](idCompany) {
    return new Promise((resolve, reject) => {
      ApiService.get("Company/GetLevelListBySector?idCompany=" + idCompany)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_LEVEL_LIST_BY_SECTOR,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_BY_ID](getEmployeeByIdRequestModel: GetEmployeeByIdRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/GetEmployeeById", getEmployeeByIdRequestModel)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_EMPLOYEE, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_POSITION_LIST](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetEmployeePositionList", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_POSITION_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.ADD_EMPLOYEE_POSITION](requestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/AddEmployeePosition", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_EMPLOYEE_POSITION](requestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/UpdateEmployeePosition", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_POSITION_BY_ID](getEmployeePositionByIdRequestModel: GetEmployeePositionByIdRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetEmployeePositionById", getEmployeePositionByIdRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_EMPLOYEE_POSITION, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_ALL_SCENARIO_FOR_COMPANY](getAllScenarioForCompanyRequestModel: GetAllScenarioForCompanyRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/GetAllScenarioForCompany", getAllScenarioForCompanyRequestModel)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_ALL_SCENARIO_FOR_COMPANY,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.COPY_SCENARIO_FROM_REFERENCE_SCENARIO](requestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/CopyScenarioFromReferenceScenario", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_PERFORMANCES](payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("Company/GetEmployeePerformancesForNewEmployee")
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_PERFORMANCES,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_MANAGER_INCREASE_RATE_BY_EMPLOYEE](
    requestModel: IncreaseRateRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/UpdateManagerIncreaseRateByEmployee",
        requestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_TITLES](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetEmployeeTitles", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_EMPLOYEE_TITLES, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.DELETE_EMPLOYEE](deleteEmployeeRequestModel: DeleteEmployeeRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/DeleteEmployee", deleteEmployeeRequestModel)
        .then((response) => {
          // request succeeded
          resolve(response); // return response data to calling function
        })
        .catch((error) => {
          // request failed
          reject(error); // return error to calling function
        });
    });
  }

  @Action
  [Actions.COLLECTIVE_HIDE_SELECTED_EMPLOYEES](
    requestModel: CollectiveEmployeeHidingRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/CollectiveHideSelectedEmployees", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_EMPLOYEE_VISIBLE_INFORMATION](
    requestModel: EmployeeHidingRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/UpdateEmployeeVisibleInformation", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_LOCATIONS](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetEmployeeLocations", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_LOCATIONS,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_LOCATION_FACTORS_LIST](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetLocationFactorList", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_LOCATION_FACTORS_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.INSERT_LOCATION_FACTOR](
    locationFactorRequestModel: LocationFactorRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/InsertLocationFactor",
        locationFactorRequestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_SCENARIO_STATUS_BY_ID](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/GetScenarioStatus", excelPackageParameterRequestModel)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_SCENARIO_STATUS, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_TERM_YEAR_COMBO_LIST]() {
    return new Promise((resolve, reject) => {
      ApiService.get("Company/GetTermYearComboList")
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_TERM_YEAR_COMBO_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.SEND_SALARY_LETTER_MAIL](
    requestModel: SalaryLetterMailRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/SendSalaryLetterMail", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_USER_AUTHORITY_BY_EXCEL_PACKAGE](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetUserAuthorityByExcelPackage",
        excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_USER_AUTHORITY_BY_EXCEL_PACKAGE,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.CALCULATE_SENIORITY_INFORMATION](calculateSeniorityInformationRequestModel: CalculateSeniorityInformationRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/CalculateSeniorityInformation", calculateSeniorityInformationRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.COLLECTIVE_FINAL_WAGE_RATE_INCREASE_FOR_SELECTED_EMPLOYEES](
    requestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/CollectiveFinalWageRateIncreaseForSelectedEmployees",
        requestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.COLLECTIVE_FINAL_WAGE_INCREASE_FOR_SELECTED_EMPLOYEES](
    requestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/CollectiveFinalWageIncreaseForSelectedEmployees",
        requestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.COLLECTIVE_MANAGER_RECOMMENDATION_WAGE_RATE_INCREASE_FOR_SELECTED_EMPLOYEES](
    requestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/CollectiveManagerRecommendationWageRateIncreaseForSelectedEmployees",
        requestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.COLLECTIVE_MANAGER_RECOMMENDATION_WAGE_INCREASE_FOR_SELECTED_EMPLOYEES](
    requestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/CollectiveManagerRecommendationWageIncreaseForSelectedEmployees",
        requestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_EMPLOYEE_NOTE](requestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/UpdateEmployeeNote", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_POLICY_FACTOR_NOTE](getPolicyFactorNoteRequestModel: GetPolicyFactorNoteRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetPolicyFactorNote", getPolicyFactorNoteRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_POLICY_FACTOR_NOTE,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.ADD_OR_UPDATE_POLICY_FACTOR_NOTE](
    requestModel: PolicyFactorNoteRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/AddOrUpdatePolicyFactorNote", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.IS_THERE_ANY_RECORDED_POLICY_FACTOR_NOTE](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/IsThereAnyRecordedPolicyFactorNote", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_POLICY_FACTOR_NOTE_KEY_VALUE_MODEL,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_ALL_POLICY_FACTOR_NOTES](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/GetAllPolicyFactorNotes", excelPackageParameterRequestModel)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_ALL_POLICY_FACTOR_NOTES,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_ALL_NOTE_TITLES]() {
    return new Promise((resolve, reject) => {
      ApiService.get("Company/GetAllNoteTitles")
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_ALL_NOTE_TITLES,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.INSERT_ALL_RATING_SCENARIO_TO_VALUE_FOR_PACKAGE](
    requestModel: InsertAllRatingScenarioToValueRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/InsertAllRatingScenarioToValueForPackage", requestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_POLICY_MANAGEMENT_OPTION_COMBO_LIST](getPolicyManagementOptionComboListRequestModel: GetPolicyManagementOptionComboListRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/GetPolicyManagementOptionComboList", getPolicyManagementOptionComboListRequestModel)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_POLICY_MANAGEMENT_OPTION_COMBO_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_POLICY_TYPES](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetEmployeePolicyTypesForNewEmployee", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_POLICY_TYPES_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_PIR_CALCULATION_PARAMETER](
    excelPackageParameterRequestModel: ExcelPackageParameterRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetPirCalculationParameter", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_GET_PIR_CALCULATION_PARAMETER,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_PIR_CALCULATION_PARAMETER](
    requestModel: UpdatePırCalculationParameterRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/UpdatePırCalculationParameter", requestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.COPY_POLICY_FROM_REFERENCE_EXCEL_PACKAGE](
    requestModel: CopyPolicyFromReferenceExcelPackageRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/CopyPolicyFromReferenceExcelPackage", requestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.INSERT_POLICY_FROM_EXCEL](
    requestModel: InsertPolicyFromExcelRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/InsertPolicyFromExcel", requestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  
  @Action
  [Actions.APPLY_POLICY_AGEING](
    requestModel: ApplyPolicyAgeingRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/ApplyPolicyAgeing", requestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.APPLY_MARKET_AGEING](
    requestModel: ApplyMarketAgeingRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/ApplyMarketAgeing", requestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_COMPANY_FOR_NEW_EMPLOYEE](
    excelPackageParameterRequestModel: ExcelPackageParameterRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetEmployeeCompanyForNewEmployee", excelPackageParameterRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_COMPANY_FOR_NEW_EMPLOYEE,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_POSITION_EMPLOYEES](
    getPositionEmployeeListRequestModel: GetPositionEmployeeListRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Company/GetPositionEmployeeList", getPositionEmployeeListRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_POSITION_EMPLOYEE_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_SALES_PREMIUM_RATE_BY_EMPLOYEE](
    requestModel: SalesPremiumRateRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/UpdateSalesPremiumRateByEmployee", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_FINAL_wAGE_BY_EMPLOYEE](
    requestModel: FinalWageRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/UpdateFinalWageByEmployee", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_SCENARIO_NAME](
    requestModel: UpdateScenarioNameRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post("Company/UpdateScenarioName", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}
