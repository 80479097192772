export class InsertPolicyFromExcelRequestModel {
    guidExcelPackage: string;
    guidPolicyManagementOption: string; //1: Şirket Seviyesi Bazında, 2: Çoklu Politika Tipi
    insertPolicyDetailFromExcelRequestModel: Array<InsertPolicyDetailFromExcelRequestModel>;
}

export class InsertPolicyDetailFromExcelRequestModel {
    level: number;
    status: string;
    employeeType: string;
    policyType: string;
    pastPolicy: number;
    policy: number;
    currentYearMarketValue: number | null;
    newYearMarketValue: number | null;
    marketGrade: number | null;
}