import { App, computed, ref } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { RefreshTokenLoginRequestModel } from "@/store/modules/companyManagement/_models/RequestModels/RefreshTokenLoginRequestModel";
import JwtService from "./JwtService";
import Swal from "sweetalert2";
import router from "@/router";
import { throwError } from "rxjs";

class ApiService {
  public static vueInstance: App;

  //Initialize vue axios
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.vueInstance.axios.defaults.headers.common['Content-Type'] = 'application/json';

    ApiService.vueInstance.axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        // Burada istek gönderilmeden önce yapılacak işlemleri gerçekleştirin
        // Örneğin, isteği manipüle etmek veya token eklemek gibi işlemleri yapabilirsiniz
        const token = localStorage.getItem("access_token");
        if (token) {
          config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );

    ApiService.vueInstance.axios.interceptors.response.use(
      (response: AxiosResponse) => {
        // Burada başarılı bir yanıt alındığında yapılacak işlemleri gerçekleştirin
        return response;
      },
      async (err) => {
        const originalConfig = err.config;
        
        if (originalConfig.url !== "/sign-in" && err.response && err.response.status === 401 && !originalConfig._retry) {
          // Flag to prevent multiple token refresh requests
          originalConfig._retry = true;

          try {
            const req = new RefreshTokenLoginRequestModel();
            req.refreshToken = localStorage.getItem("refresh_token");
            const result = await ApiService.postV2("User/RefreshTokenLogin", req);

            if (result.result) {
              localStorage.setItem("access_token", result.data.accessToken);
              localStorage.setItem("refresh_token", result.data.refreshToken);
              const user = JwtService.getUserView();
              user.accessToken = result.data.accessToken;
              user.refreshToken = result.data.refreshToken
              localStorage.setItem("user_view", JSON.stringify(user));

              const token = localStorage.getItem("access_token");
              err.config.headers[
                "Authorization"
              ] = 'Bearer ' + token;
              return new Promise((resolve, reject) => {
                axios.request(originalConfig).then(response => {
                  resolve(response);
                }).catch((err) => {
                  reject(err);
                })
              });
            }
            else {
              Swal.fire({
                icon: "error",
                text: "Token süreniz bittiği için yeniden giriş yapmalısınız!",
                showConfirmButton: true,
                width: 500,
              }).then(() => {
                localStorage.clear();
                sessionStorage.clear();
                router.push({ name: "sign-in" });
              });
            }
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
        else {
          Swal.fire({
            icon: "warning",
            text: err.response.data.Message,
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            width: 500,
            confirmButtonText: "Tamam",
          });
        }
        
        return throwError(() => err);
      }
    );
  }

  public static get(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios
      .get(resource)
      .then((response: AxiosResponse) => {
        //Farklı sekmelerde farklı kullanıcı bilgileri ile giriş yapıldığında son giriş yapan kullanıcı bilgileri local'e setleniyor.
        //Diğer kullanıcıyı dışarı atıyoruz.
        if (response.data.result == false) {
          const user = JwtService.getUserView();
          const sessionUserId: any = JwtService.getUserId();

          if (user.idUser != sessionUserId) {
            sessionStorage.removeItem("id_excel_package");
            sessionStorage.removeItem("id_company");
            sessionStorage.removeItem("id_policy_management_option");
            sessionStorage.removeItem("id_user");
            router.push({ name: "sign-in" });
          }
        }
        return response.data;
      })
  }

  public static post(resource: string, data: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios
      .post(resource, data)
      .then((response: AxiosResponse) => {
        return response.data;
      })
  }

  public static async postV2(url: string, data: any) {
    const response: AxiosResponse = await ApiService.vueInstance.axios.post(url, data);
    return response.data;
  }

  // public static get(url: string): Promise<AxiosResponse> {
  //   return ApiService.vueInstance.axios.get(url)
  //     .then((response: AxiosResponse) => {
  //       return response.data;
  //     })
  //     .catch((error: any) => {
  //       throw error;
  //     });
  // }

  // public static post(url: string, data: any): Promise<AxiosResponse> {
  //   return ApiService.vueInstance.axios.post(url, data)
  //     .then((response: AxiosResponse) => {
  //       return response.data;
  //     })
  //     .catch((error: any) => {
  //       throw error;
  //     });
  // }
}

export default ApiService;