import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "../../src/core/services/JwtService";
import { sharedService } from "@/core/services/SharedService";
import { GetRoleModulesListRequestModel } from "@/store/modules/userManagement/_models/RequestModels/GetRoleModulesListRequestModel";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/landing-page",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/userManagement",
        name: "userManagement",
        component: () => import("@/components/page-layouts/UserManagement.vue"),
        children: [
          {
            path: "/userManagement/management",
            name: "management",
            component: () => import("@/views/userManagement/Management.vue"),
          },
          {
            path: "/userManagement/roleManagement",
            name: "roleManagement",
            component: () =>
              import("@/views/userManagement/RoleManagement.vue"),
          },
          {
            path: "/userManagement/populationAuthorization",
            name: "populationAuthorization",
            component: () =>
              import("@/views/userManagement/PopulationAuthorization.vue"),
          },
        ],
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/companyManagement",
        name: "companyManagement",
        component: () =>
          import("@/components/page-layouts/CompanyManagement.vue"),
        children: [
          {
            path: "/companyManagement/employeeManagement",
            name: "employeeManagement",
            component: () =>
              import("@/views/companyManagement/EmployeeManagement.vue"),
          },
          {
            path: "/companyManagement/policy",
            name: "policy",
            component: () => import("@/views/companyManagement/Policy.vue"),
          },
          {
            path: "/companyManagement/factors",
            name: "factors",
            component: () => import("@/views/companyManagement/Factors.vue"),
          },
          {
            path: "/companyManagement/dashboard",
            name: "dashboard",
            component: () => import("@/views/companyManagement/Dashboard.vue"),
          },
          {
            path: "/companyManagement/previewPage",
            name: "previewPage",
            component: () =>
              import("@/views/companyManagement/PreviewPage.vue"),
          },
          {
            path: "/companyManagement/companies",
            name: "companies",
            component: () =>
              import("@/views/companyManagement/CompanyManagement.vue"),
          },
          {
            path: "/companyManagement/positionManagement",
            name: "positionManagement",
            component: () =>
              import("@/views/companyManagement/PositionManagement.vue"),
          },
          {
            path: "/companyManagement/scenarioManagement",
            name: "scenarioManagement",
            component: () =>
              import("@/views/companyManagement/ScenarioManagement.vue"),
          },
          {
            path: "/companyManagement/organizationChart",
            name: "organizationChart",
            component: () =>
              import("@/views/companyManagement/OrganizationChart.vue"),
          },
        ],
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/orgChartManagement",
        name: "orgChartManagement",
        component: () => import("@/components/page-layouts/OrgChartManagement.vue"),
        children: [
          {
            path: "/orgChartManagement/orgChart",
            name: "orgChart",
            component: () => import("@/views/orgChart/Chart.vue"),
          },
          {
            path: "/orgChartManagement/orgChartScenario",
            name: "orgChartScenario",
            component: () => import("@/views/orgChart/OrgChartScenarioManagement.vue"),
          },
        ],
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/authentication/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/authentication/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/landing-page",
    name: "landing-page",
    component: () => import("@/views/landing-page/LandingPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const getRoleModulesListRequestModel = new GetRoleModulesListRequestModel();

router.beforeEach((to, from, next) => {
  // eğer router'ların meta özelliğine requiresAuth true girilmişse
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // eğer kullanıcı girişi yapılmamışsa giriş ekranına yönlendiriyoruz...
    if (!store.getters.isUserAuthenticated) {
      next({ name: "sign-in" });
    } else {
      store.commit(Mutations.RESET_LAYOUT_CONFIG);

      const user = JwtService.getUserView();
      store.dispatch(Actions.VERIFY_AUTH, { api_token: user.accessToken });

      //landing-page'e yönlendirme yaparken kullanıcının modüllerini çekiyoruz...
      
      getRoleModulesListRequestModel.idRole = user.idRole;
      store.dispatch(Actions.GET_ROLE_MODULE_LIST, getRoleModulesListRequestModel);

      //Bir şirket açılıp, ilk defa excel import edildiğinde, Kullanıcı f5 yapmak zorunda kalmadan,
      //sayfa geçişlerinde excel listesini çağıran metodu tetikler
      const idExcelPackage = store.getters.getIdExcelPackage;
      const idCompany = store.getters.getIdCompany;
      const routeName = to.fullPath.split("/")[1];
      const menuName = to.fullPath.split("/")[2];
      
      if (
        (idExcelPackage == null || idExcelPackage == undefined) &&
        idCompany != null &&
        idCompany != undefined &&
        routeName === "companyManagement"
      ) {
        sharedService.reloadExcelPackageListFlag(true);
      }
      else if(
        (idExcelPackage != null || idExcelPackage != undefined) &&
        idCompany != null &&
        idCompany != undefined &&
        routeName === "companyManagement"
      ){
        sharedService.reloadExcelPackageListFlagForAnotherPages(true);
      }

      if (menuName == "dashboard") {
        sharedService.hideExcelPackageComboList(true);
      } else {
        sharedService.hideExcelPackageComboList(false);
      }

      // Scroll page to top on every route change
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);

      next();
    }
  } else {
    next(); // eğer router'ların meta özelliğine requiresAuth girilmemişse
  }

  // // reset config to initial state
  // store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // // Scroll page to top on every route change
  // setTimeout(() => {
  //   window.scrollTo(0, 0);
  // }, 100);
});

export default router;
