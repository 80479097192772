import { UserCompanyResponseModel } from "./UserCompanyResponseModel";
import { UserRoleResponseModel } from "./UserRoleResponseModel";

export class LoginResponseModel {
  result: boolean;
  userViewModel: UserViewModel = new UserViewModel();
}

export class UserViewModel {
  idUser: number;
  userName: string;
  nameSurname: string;
  idRole: number;
  roleDesc: string;
  email: string;
  accessToken: string | null;
  refreshToken: string | null;
  userCompanies: Array<UserCompanyResponseModel>;
  logoImage: string;
}
