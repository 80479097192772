export class AddEmployeeRequestModel {
  guidEmployee: string | null;
  guidPackage: string;
  externalIdEmployee: string;
  guidLevel: string | null;
  level: string | null;
  guidEmployeeLevelStatus: string | null;
  status: string | null;
  name: string;
  surname: string;
  positionCriticalityLevel: number;
  budget: string | null;
  lastYearPerformance: string;
  thisYearPerformance: string;
  guidEmployeeLocation: string | null;
  location: string | null;
  guidWorkUnit: string | null;
  workUnit: string;
  guidDepartment: string | null;
  department: string;
  guidPosition: string | null;
  position: string;
  firstManager: string;
  lastManager: string;
  idNormType: number | null;
  dateOfBirth: Date | string | null;
  startDateOfEmployment: Date | string | null;
  startDateOfPosition: Date | string | null;
  positionCriticality: string | null;
  employeeCriticality: string | null;
  wage: number | null;
  gender: string;
  email: string;
  guidEmployeeTitle: string | null;
  titleName: string | null;
  employeeType: string | null;
  retirementStatus: string | null;
  numberOfSalariesInYear: number | null;
  employeeNote: string;
  guidEmployeePolicyType: string | null;
  policyType: string | null;
  guidEmployeeCompany: string | null;
  employeeCompanyName: string | null;
  salesPremiumRate: number | null;
  employeeSubDepartment: string; 
}