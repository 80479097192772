export class CompanyRequestModel {
  guidCompany: string;
  guidSector: string;
  companyCode: string;
  companyName: string;
  email: string;
  address: string;
  phoneNumber: string;
  isParentCompany: boolean | null;
}
