import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { EmployeeExcelExportRequestModel } from "./_models/RequestModels/EmployeeExcelExportRequestModel";
import { EmployeeWageInfoRequestModel } from "./_models/RequestModels/EmployeeWageInfoRequestModel";
import { EmployeeExcelExportResponseModel } from "./_models/ResponseModels/EmployeeExcelExportResponseModel";
import { DashboardResponseModel } from "./_models/ResponseModels/DashboardResponseModel";
import { ExcelTemplateResponseModel } from "./_models/ResponseModels/ExcelTemplateResponseModel";
import { PreviewPageRequestModel } from "./_models/RequestModels/PreviewPageRequestModel";
import { PreviewPageResponseModel } from "./_models/ResponseModels/PreviewPageResponseModel";
import { EmployeesWhoHasTotalFactorMoreThanThirtyThreePercentResponseModel } from "./_models/ResponseModels/EmployeesWhoHasTotalFactorMoreThanThirtyThreePercentResponseModel";
import { EmployeeWageChangeResponseModel } from "./_models/ResponseModels/EmployeeWageChangeResponseModel";
import { GetWageInfoForEmployeeRequestModel } from "./_models/RequestModels/GetWageInfoForEmployeeRequestModel";
import { UpdateCalculateMathematicalValuesForDashboardRequestModel } from "./_models/RequestModels/UpdateCalculateMathematicalValuesForDashboardRequestModel";
import { CompleteScenarioRequestModel } from "./_models/RequestModels/CompleteScenarioRequestModel";
import { UpdatePreviewPageChartViewForUserRequestModel } from "./_models/RequestModels/UpdatePreviewPageChartViewForUserRequestModel";
import { GetExcelTemplatesRequestModel } from "./_models/RequestModels/GetExcelTemplatesRequestModel";
import { ExcelPackageParameterRequestModel } from "@/models/global/ExcelPackageParameterRequestModel";

@Module
export default class CompanyDashboardModule extends VuexModule {
  employeeWageInfoRequestModel: EmployeeWageInfoRequestModel;
  excelTemplates: ExcelTemplateResponseModel;
  dashboardResponseModel: DashboardResponseModel;
  employeeExcelExportResponseModel: Array<EmployeeExcelExportResponseModel>;
  previewPageDataResponseModel: PreviewPageResponseModel;
  previewPageDataRequestModel: PreviewPageRequestModel;
  employeesWhoHasTotalFactorMoreThanThirtyThreePercentResponseModel: Array<EmployeesWhoHasTotalFactorMoreThanThirtyThreePercentResponseModel>;
  employeeWageChangeResponseModel: Array<EmployeeWageChangeResponseModel>;
  idPreviewPageChartViewForUser: number;

  get getEmployeeWageInfoRequestModel() {
    return this.employeeWageInfoRequestModel;
  }
  get getEmployeeExcelExportResponseModel() {
    return this.employeeExcelExportResponseModel;
  }
  get getExcelTemplateList() {
    return this.excelTemplates;
  }
  get getDashboardResponseModel() {
    return this.dashboardResponseModel;
  }
  get getPreviewPageResponseModel() {
    return this.previewPageDataResponseModel;
  }
  get getPreviewPageRequestModel() {
    return this.previewPageDataRequestModel;
  }
  get getEmployeesWhoHasTotalFactorMoreThanThirtyThreePercentResponseModel() {
    return this
      .employeesWhoHasTotalFactorMoreThanThirtyThreePercentResponseModel;
  }
  get getEmployeeWageChangeResponseModel() {
    return this.employeeWageChangeResponseModel;
  }
  get getIdPreviewPageChartViewForUser() {
    return this.idPreviewPageChartViewForUser;
  }

  @Mutation
  [Mutations.SET_WAGE_INFO_FOR_EMPLOYEE](employeeWageInfo) {
    this.employeeWageInfoRequestModel = employeeWageInfo;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_DATA_FOR_EXCEL_EXPORT](model) {
    this.employeeExcelExportResponseModel = model;
  }

  @Mutation
  [Mutations.SET_EXCEL_TEMPLATES](model) {
    this.excelTemplates = model;
  }

  @Mutation
  [Mutations.SET_DASHBOARD_DATA](model) {
    this.dashboardResponseModel = model;
  }

  @Mutation
  [Mutations.SET_PREVIEW_PAGE_DATA](model) {
    // this.previewPageDataResponseModel = model;
    const data = new PreviewPageResponseModel();
    data.currentStructureChartResponseModel =
      model?.currentStructureChartResponseModel;
    data.systemRecommendationChartResponseModel =
      model?.systemRecommendationChartResponseModel;
    data.finalChartResponseModel = model?.finalChartResponseModel;
    data.managerRecommendationChartResponseModel =
      model?.managerRecommendationChartResponseModel;
    data.filterTableResponseModel = model?.filterTableResponseModel;
    data.summaryTableResponseModel = model?.summaryTableResponseModel;
    data.wageRangeResponseModel = model?.wageRangeResponseModel;
    this.previewPageDataResponseModel = data;
  }

  @Mutation
  [Mutations.SET_PREVIEW_PAGE_REQUEST_MODEL](model) {
    this.previewPageDataRequestModel = model;
  }

  @Mutation
  [Mutations.SET_UPDATE_CALCULATE_MATHEMATICAL_VALUES_FOR_DASHBOARD](model) {
    this.employeesWhoHasTotalFactorMoreThanThirtyThreePercentResponseModel =
      model;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE_WAGE_CHANGE_LIST](model) {
    this.employeeWageChangeResponseModel = model;
  }

  @Mutation
  [Mutations.SET_PREVIEW_PAGE_CHART_VIEW_FOR_USER](model) {
    this.idPreviewPageChartViewForUser = model;
  }

  @Action
  [Actions.UPDATE_CALCULATE_MATHEMATICAL_VALUES_FOR_DASHBOARD]
    (updateCalculateMathematicalValuesForDashboardRequestModel: UpdateCalculateMathematicalValuesForDashboardRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Dashboard/UpdateCalculateMathematicalValuesForDashboard", updateCalculateMathematicalValuesForDashboardRequestModel
      )
        .then((response: any) => {
          if (
            response.result &&
            response.data != null &&
            response.data != undefined
          ) {
            this.context.commit(
              Mutations.SET_UPDATE_CALCULATE_MATHEMATICAL_VALUES_FOR_DASHBOARD,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_WAGE_INFO_FOR_EMPLOYEE](getWageInfoForEmployeeRequestModel: GetWageInfoForEmployeeRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Dashboard/GetWageInfoForEmployee",
        getWageInfoForEmployeeRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_WAGE_INFO_FOR_EMPLOYEE,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_WAGE_INFO_FOR_EMPLOYEE](
    employeeWageInfoRequestModel: EmployeeWageInfoRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Dashboard/UpdateWageInfoForEmployee",
        employeeWageInfoRequestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_MANAGER_WAGE_INFO_FOR_EMPLOYEE](
    employeeWageInfoRequestModel: EmployeeWageInfoRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Dashboard/UpdateManagerWageInfoForEmployee",
        employeeWageInfoRequestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_DATA_FOR_EXCEL_EXPORT](
    employeeExcelExportRequestModel: EmployeeExcelExportRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Dashboard/GetEmployeeDataForExcelExport",
        employeeExcelExportRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_DATA_FOR_EXCEL_EXPORT,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EXCEL_TEMPLATES](getExcelTemplatesRequestModel: GetExcelTemplatesRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Dashboard/GetExcelTemplates", getExcelTemplatesRequestModel)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_EXCEL_TEMPLATES, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_DASHBOARD_DATA](requestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Dashboard/GetDashboardData", requestModel)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_DASHBOARD_DATA, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.COMPLETE_SCENARIO](completeScenarioRequestModel: CompleteScenarioRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Dashboard/CompleteScenario", completeScenarioRequestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_PREVIEW_PAGE_DATA](requestModel: PreviewPageRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Dashboard/GetPreviewPageData", requestModel)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_PREVIEW_PAGE_DATA, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.SAVE_PREVIEW_PAGE_REQUEST_MODEL](
    requestModel: PreviewPageRequestModel
  ) {
    return new Promise((resolve, reject) => {
      this.context.commit(
        Mutations.SET_PREVIEW_PAGE_REQUEST_MODEL,
        requestModel
      );
    });
  }

  @Action
  [Actions.GET_EMPLOYEE_WAGE_CHANGE_LIST](getEmployeeWageChangeListRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Dashboard/GetEmployeeWageChangeList", getEmployeeWageChangeListRequestModel
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EMPLOYEE_WAGE_CHANGE_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_PREVIEW_PAGE_CHART_VIEW_FOR_USER](updatePreviewPageChartViewForUserRequestModel: UpdatePreviewPageChartViewForUserRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "Dashboard/UpdatePreviewPageChartViewForUser", updatePreviewPageChartViewForUserRequestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_PREVIEW_PAGE_CHART_VIEW_FOR_USER]() {
    return new Promise((resolve, reject) => {
      ApiService.get("Dashboard/GetPreviewPageChartViewForUser")
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_PREVIEW_PAGE_CHART_VIEW_FOR_USER,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GO_BACK_TO_SYSTEM_RECOMMENDATION](excelPackageParameterRequestModel: ExcelPackageParameterRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("Dashboard/GoBackToSystemRecommendation", excelPackageParameterRequestModel)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
