export class CompanyMarketDataRequestModel {
  guidCompanyMarketData: string;
  guidExcelPackage: string;
  guidEmployeeLevelStatus: string;
  employeeType: string;
  guidEmployeePolicyType: string | null;
  marketDataType: number;
  currentYearMarketValue: number;
  newYearMarketValue: number;
  marketGrade: number;
}
