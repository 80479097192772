import { UserViewModel } from "@/store/modules/auth/_models/LoginResponseModel";
//import { UserRoleResponseModel } from "@/store/modules/auth/_models/UserRoleResponseModel";

const ACCESS_TOKEN = "access_token" as string;
const REFRESH_TOKEN = "refresh_token" as string;
const USER_VIEW = "user_view" as string;
//const USER_ROLE = "user_role" as string;
const ID_EXCEL_PACKAGE = "id_excel_package" as string;
const ID_COMPANY = "id_company" as string;
const ID_POLICY_MANAGEMENT_OPTION = "id_policy_management_option" as string;
const ID_USER = "id_user" as string;
const ID_EMPLOYEE_MANAGEMENT_OPTION = "id_employee_management_option" as string;
const ID_WAGE_MANAGEMENT_OPTION = "id_wage_management_option" as string;
const GUID_POLICY_MANAGEMENT_OPTION = "guid_policy_management_option" as string;

export const getAccessToken = (): string | null => {
  return window.localStorage.getItem(ACCESS_TOKEN);
};

export const saveAccessToken = (token: string): void => {
  window.localStorage.setItem(ACCESS_TOKEN, token);
};

export const destroyAccessToken = (): void => {
  window.localStorage.removeItem(ACCESS_TOKEN);
};

export const getRefreshToken = (): string | null => {
  return window.localStorage.getItem(REFRESH_TOKEN);
};

export const saveRefreshToken = (token: string): void => {
  window.localStorage.setItem(REFRESH_TOKEN, token);
};

export const destroyRefreshToken = (): void => {
  window.localStorage.removeItem(REFRESH_TOKEN);
};

export const saveUserView = (user = UserViewModel): void => {
  window.localStorage.setItem(USER_VIEW, JSON.stringify(user));
};

export const getUserView = (): UserViewModel => {
  const user = window.localStorage.getItem(USER_VIEW);
  if (user != null) {
    return JSON.parse(user);
  }
  return new UserViewModel();
};

export const destroyUserView = (): void => {
  window.localStorage.removeItem(USER_VIEW);
};

// export const saveUserRole = (userRole): void => {
//   window.localStorage.setItem(USER_ROLE, JSON.stringify(userRole));
// };

// export const getUserRole = (): UserRoleResponseModel | null => {
//   const userRole = window.localStorage.getItem(USER_ROLE);
//   if (userRole != null) {
//     return JSON.parse(userRole);
//   }
//   return new UserRoleResponseModel();
// };

// export const destroyUserRole = (): void => {
//   window.localStorage.removeItem(USER_ROLE);
// };

export const saveExcelPackageId = (idExcelPackage): void => {
  window.sessionStorage.setItem(ID_EXCEL_PACKAGE, idExcelPackage);
};

export const getExcelPackageId = () => {
  return window.sessionStorage.getItem(ID_EXCEL_PACKAGE);
};

export const destroyExcelPackageId = (): void => {
  window.sessionStorage.removeItem(ID_EXCEL_PACKAGE);
};

export const saveCompanyId = (idCompany): void => {
  window.sessionStorage.setItem(ID_COMPANY, idCompany);
};

export const getCompanyId = () => {
  return window.sessionStorage.getItem(ID_COMPANY);
};

export const destroyCompanyId = (): void => {
  window.sessionStorage.removeItem(ID_COMPANY);
};

export const savePolicyManagementOptionId = (idPolicyManagementOption): void => {
  window.sessionStorage.setItem(ID_POLICY_MANAGEMENT_OPTION, idPolicyManagementOption);
};

export const getPolicyManagementOptionId = () => {
  return window.sessionStorage.getItem(ID_POLICY_MANAGEMENT_OPTION);
};

export const destroyPolicyManagementOptionId = (): void => {
  window.sessionStorage.removeItem(ID_POLICY_MANAGEMENT_OPTION);
};

export const saveEmployeeManagementOptionId = (idEmployeeManagementOption): void => {
  window.sessionStorage.setItem(ID_EMPLOYEE_MANAGEMENT_OPTION, idEmployeeManagementOption);
};

export const getEmployeeManagementOptionId = () => {
  return window.sessionStorage.getItem(ID_EMPLOYEE_MANAGEMENT_OPTION);
};

export const destroyEmployeeManagementOptionId = (): void => {
  window.sessionStorage.removeItem(ID_EMPLOYEE_MANAGEMENT_OPTION);
};

export const saveUserId = (idUser): void => {
  window.sessionStorage.setItem(ID_USER, idUser);
};

export const getUserId = () => {
  return window.sessionStorage.getItem(ID_USER);
};

export const destroyUserId = (): void => {
  window.sessionStorage.removeItem(ID_USER);
};

export const saveWageManagementOptionId = (idWageManagementOption): void => {
  window.sessionStorage.setItem(ID_WAGE_MANAGEMENT_OPTION, idWageManagementOption);
};

export const getWageManagementOptionId = () => {
  return window.sessionStorage.getItem(ID_WAGE_MANAGEMENT_OPTION);
};

export const destroyWageManagementOptionId = (): void => {
  window.sessionStorage.removeItem(ID_WAGE_MANAGEMENT_OPTION);
};

export const savePolicyManagementOptionGuid = (guidPolicyManagementOption): void => {
  window.sessionStorage.setItem(GUID_POLICY_MANAGEMENT_OPTION, guidPolicyManagementOption);
};

export const getPolicyManagementOptionGuid = () => {
  return window.sessionStorage.getItem(GUID_POLICY_MANAGEMENT_OPTION);
};

export const destroyPolicyManagementOptionGuid = (): void => {
  window.sessionStorage.removeItem(GUID_POLICY_MANAGEMENT_OPTION);
};

export default {
  getAccessToken,
  saveAccessToken,
  destroyAccessToken,
  getRefreshToken,
  saveRefreshToken,
  destroyRefreshToken,
  saveUserView,
  getUserView,
  destroyUserView,
  // saveUserRole,
  // getUserRole,
  // destroyUserRole,
  saveExcelPackageId,
  getExcelPackageId,
  destroyExcelPackageId,
  saveCompanyId,
  getCompanyId,
  destroyCompanyId,
  savePolicyManagementOptionId,
  getPolicyManagementOptionId,
  destroyPolicyManagementOptionId,
  saveUserId,
  getUserId,
  destroyUserId,
  saveEmployeeManagementOptionId,
  getEmployeeManagementOptionId,
  destroyEmployeeManagementOptionId,
  saveWageManagementOptionId,
  getWageManagementOptionId,
  destroyWageManagementOptionId,
  savePolicyManagementOptionGuid,
  getPolicyManagementOptionGuid,
  destroyPolicyManagementOptionGuid
};
